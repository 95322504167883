<template>
	<v-dialog v-model="show_bid_box" persistent width="auto" fullscreen transition="slide-fade" light>
		<v-row no-gutters class="bid" style="background: rgba(0, 0, 0, 0.4)">
			<v-col cols="12" lg="6" md="5" sm="3"></v-col>
			<v-col
				cols="11"
				lg="6"
				md="7"
				sm="9"
				style="height: 100vh; margin-left: auto; max-width: 700px; width: 100%"
				class="bid-box pa-0 d-flex flex-column"
			>
				<div class="px-6 pb-6 end-shadow" style="position: relative">
					<v-row no-gutters class="pl-6 d-flex align-center" style="height: 65px; border-bottom: 1px solid #e7eaf3; margin: 0px -24px 0px -24px">
						<v-btn v-on:click=";(show_bid_box = false), (error_message = '')" class="my-back-btn" depressed>
							<img :src="$store.state.icons.icons['LeftArrow']" class="back-btn" alt />
							Back
						</v-btn>
					</v-row>
				</div>

				<div class="bid-items-content px-6">
					<div class="header-bid-change">Change Price</div>
					<v-form v-model="valid" ref="form" lazy-validation>
						<app-bid-items v-model="bid_data" :listitems="listitems"></app-bid-items>

						<app-show-bid-total class="d-none" v-model="calculateTotal"></app-show-bid-total>
					</v-form>
				</div>

				<div class="bid-footer d-flex flex-column mt-auto mb-6">
					<div class="bid-footer-layer"></div>
					<div class="px-6 pt-6 d-flex">
						<p class="heading-sf18">Total:</p>
						<p class="heading-sf18 font-shadow ml-auto">
							{{ this.$options.filters.currency(total) }}
						</p>
					</div>

					<div class="px-6 mt-4">
						<v-btn class="my-p-btn my_elevation" id="my_elevation" depressed block light :disabled="Show" @click="submitBid"
							>Submit your Price Change</v-btn
						>
					</div>
				</div>

				<div v-if="!snackbar" class="custom-error d-flex justify-center mb-3">{{ error_message }}</div>
				<v-snackbar app color="error" top right v-model="snackbar" :timeout="timeout">
					{{ error_message }}
				</v-snackbar>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script>
const BidItems = () => import('./BidItemComponent')
const ShowBidTotal = () => import('./ShowBidTotalComponent')
const Feedback = () => import('./FeedbackComponent')
const ListInteraction = () => import('./ListInteractionComponent')
const BidHeader = () => import('../bidcomponent/BidHeader')
import { localDateFromDateTime, timeFormat, covLocalTime, covLocalDate } from '../../services/commonservice'
import { getBid } from '../../services/bidservice'
import firebase from 'firebase/app'
import { AXIOS } from '../../plugins/axios'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../../config/variable'

export default {
	components: {
		AppBidItems: BidItems,
		AppShowBidTotal: ShowBidTotal,
		AppFeedback: Feedback,
		AppListInteraction: ListInteraction,
		AppBidHeader: BidHeader,
	},

	data() {
		return {
			lastObjId: '',
			snackbar: false,
			timeout: 3000,
			bid_id: '',
			valid: true,
			is_disabled: false,
			isShowError: false,
			show_bid_box: false,
			error_message: '',
			show_message: 'none',
			bid_data: {
				check_bid: false,
				biditems: [],
			},
			listitems: [],
			bid_total: {
				sub_total: 0.0,
				shipping: 0.0,
				service_fee: 0.0,
				sales_tax: 0.0,
				tax_percentage: 0.0,
			},
		}
	},

	computed: {
		Show() {
			this.is_disabled = !this.valid
			this.error_message = ''
			return this.is_disabled
		},

		calculateTotal() {
			this.bid_total.sub_total = 0
			// this.bid_total.service_fee = 0
			// this.bid_total.sales_tax = this.bid_data.sales_tax
			// this.bid_total.shipping = this.bid_data.shipping
			this.bid_data.biditems.forEach((item) => {
				this.bid_total.sub_total += Number(item.price) * Number(item.qty)
			})

			// this.bid_total.service_fee = ((this.bid_total.sub_total + this.bid_total.shipping) * 5) / 100

			this.bid_data.sub_total = this.bid_total.sub_total
			// this.bid_data.service_fee = this.bid_total.service_fee
			// this.bid_total.tax_percentage = this.bid_data.tax_percentage
			return this.bid_total
		},

		total() {
			let total = Number(this.bid_total.sub_total)
			return parseFloat(total).toFixed(2)
		},
	},

	methods: {
		async getLastBidObjId(id) {
			const abc = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
				.doc(id)
				.collection('chats')
				.where('bidobj.isBidAcceptedRejected', '==', false)
				.get()

			abc.docs.forEach((doc) => {
				this.lastObjId = doc.id
			})
		},
		checkBidSubmit(id) {
			this.$store.commit('errorMessage', '')
			this.show_bid_box = true
			this.bid_id = id
			if (this.list_id === '') {
				this.show_bid_box = false
			}
			this.getBid()
		},

		bidHeader() {
			return {
				category_id: this.bid_data.category_id,
				list_id: this.bid_data.list_id,
				list_name: this.bid_data.list_name,
				// list_lat: this.bid_data.list_lat,
				// list_long: this.bid_data.list_long,
				// bid_lat: "",
				// bid_long: "",
				url: this.bid_data.url,
				created_at: this.bid_data.created_at,
				status: this.bid_data.status,
			}
		},

		dateFormat(data) {
			return localDateFromDateTime(data)
		},

		timeOfDelivery() {
			let st = this.bid_data.start_time
			let et = this.bid_data.end_time
			if (st != undefined && et != undefined) {
				return timeFormat(st) + ' - ' + timeFormat(et)
			}
		},

		requiredDeliveryTime() {
			let time = this.bid_data.required_delivery_time

			if (time === 'M') {
				return 'Morning'
			} else if (time === 'N') {
				return 'Noon'
			} else if (time === 'A') {
				return 'Afternoon'
			} else if (time === 'L') {
				return 'Last Stop'
			} else if (time === 'AS') {
				return 'As soon as possible'
			}
		},

		async submitBid() {
			try {
				if (this.$refs.form.validate()) {
					this.$store.commit('errorMessage', '')
					this.error_message = ''
					let modification = this.bid_data.biditems.find((item) => item.modification === 'Y')
					this.bid_data.modification = modification === undefined ? 'N' : 'Y'
					let { data } = await AXIOS.put('distributor/bid-price/' + this.bid_id, {
						bid_id: this.bid_id,
						list_id: this.bid_data.list_id,
						date_of_delivery: this.bid_data.date_of_delivery,
						time_of_delivery: this.bid_data.time_of_delivery,
						modification: this.bid_data.modification,
						start_time: this.bid_data.start_time,
						end_time: this.bid_data.end_time,
						biditems: this.bid_data.biditems,
					})

					if (data.data) {
						this.bid_data = {
							bid_id: data.data.bid_id,
							list_id: data.data.list_id,
							date_of_delivery: data.data.date_of_delivery,
							sub_total: data.data.sub_total,
							shipping: data.data.shipping,
							service_fee: data.data.service_fee,
							sales_tax: data.data.sales_tax,
							tax_percentage: data.data.tax_percentage,
							total_amount: data.data.total_amount,
							modification: data.data.modification,
							reason_ids: data.data.reason_ids,
							comments: data.data.comments,
							tag_status: data.data.tag_status,
							status: data.data.status,
							created_at: data.data.created_at,
							time_of_delivery: data.data.time_of_delivery,
							latitude: data.data.latitude,
							longitude: data.data.longitude,
							start_time: data.data.start_time,
							end_time: data.data.end_time,
							biditems: data.data.bid_items,
							list: data.data.clist,
							old_amount: data.data.old_amount,
						}
						let data_ob = {
							list: this.bid_data.list,
							bid: this.bid_data,
							contractor: {},
							notification: {},
						}

						this.$store.commit('listReset')
						this.$store.commit('listData', data_ob)

						if (data.data) {
							this.show_bid_box = false

							if (this.$store.state.chathaggle.bid_obj_id) {
								await firebase
									.firestore()
									.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
									.doc(this.$store.state.chathaggle.collection_id)
									.collection('chats')
									.doc(this.$store.state.chathaggle.bid_obj_id)
									.update({ 'bidobj.isBidAcceptedRejected': true, 'bidobj.show_change_price': false })
							}

							await firebase
								.firestore()
								.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
								.doc(this.$store.state.chathaggle.collection_id)
								.collection('chats')
								.add({
									image_url: '',
									message: 'Changed Price',
									chatRoomId: this.$store.state.chathaggle.collection_id,
									time: Date.now(),
									sendBy: `${this.$store.state.auth.user_data.company_id}DC`,
									toSend: `${this.$store.state.chathaggle.contractor_id}C`,
									type: 0,
									docType: 'object',
									acceptRejectLable: '',
									bidobj: {
										bid_id: this.bid_id,
										biditems: this.bid_data.biditems,
										clist: {
											list_id: this.bid_data.list_id,
											list_name: this.bid_data.list.list_name,
										},
										discountPercentage:
											(this.bid_data.total_amount > this.bid_data.old_amount ? '+' : '') +
											// `${(100 - (this.bid_data.total_amount * 100) / this.bid_data.old_amount).toFixed(2)}%`.toString(),
											`${(((this.bid_data.total_amount - this.bid_data.old_amount) * 100) / this.bid_data.old_amount).toFixed(
												2
											)}%`.toString(),
										distributor_id: this.$store.state.auth.user_data.id,
										isBidAcceptedRejected: false,
										sub_total: this.bid_data.sub_total,
										total_amount: this.bid_data.total_amount,
										show_change_price: true,
										object_type: 'discounted_quote',
									},
									deleteBy: null,
									docUrl: '',
									docName: '',
									docSize: '',
								})
						}

						this.getBid()
					}
				}
			} catch (error) {
				if (error.response && error.response.data) {
					this.error_message = ''
					const response = error.response
					if (response === undefined || response.status === 500) {
						this.$router.push({ path: '/500' })
					} else {
						setTimeout(() => {
							this.show_bid_box = false
							this.error_message = ''
						}, 3000)

						this.error_message = response.data.message
						this.snackbar = true
						// if (response.data.hold_status == 'limit over') {
						if (this.$store.state.chathaggle.bid_obj_id) {
							await firebase
								.firestore()
								.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
								.doc(this.$store.state.chathaggle.collection_id)
								.collection('chats')
								.doc(this.$store.state.chathaggle.bid_obj_id)
								.update('bidobj.show_change_price', false)
						}
						// }
					}
				}
			}
		},

		async getBid() {
			let data
			let data_ob = await getBid(this.bid_id)
			data_ob = {
				list: data_ob.data.clist,
				bid: data_ob.data,
				contractor: {},
				notification: {},
			}
			this.$store.commit('listReset')
			this.$store.commit('listData', data_ob)
			data = JSON.parse(JSON.stringify(this.$store.state.list.list_data))

			this.bid_data.list_id = data.list.list_id
			this.bid_data.list_name = data.list.list_name
			this.bid_data.category_id = data.list.category_id
			this.bid_data.required_delivery_date = data.list.delivery_date
			this.bid_data.required_delivery_time = data.list.delivery_time
			this.bid_data.date_of_delivery = covLocalDate(data.bid.date_of_delivery, data.bid.start_time)
			this.bid_data.time_of_delivery = data.bid.time_of_delivery
			this.bid_data.start_time = covLocalTime(data.bid.date_of_delivery, data.bid.start_time)
			this.bid_data.end_time = covLocalTime(data.bid.date_of_delivery, data.bid.end_time)
			this.bid_data.created_at = data.bid.created_at
			this.bid_data.modification = data.bid.modification
			this.bid_data.sub_total = data.bid.sub_total
			this.bid_data.shipping = data.bid.shipping
			this.bid_data.service_fee = data.bid.service_fee
			this.bid_data.tax_percentage = data.bid.tax_percentage
			this.bid_data.sales_tax = data.bid.sales_tax
			this.bid_data.notes = data.list.notes
			this.bid_data.biditems = data.bid.biditems
			this.listitems = data.list.listitems
			// this.bid_data.list_lat = data.list.latitude;
			// this.bid_data.list_long = data.list.longitude;
			this.bid_data.status = data.bid.status
			this.bid_data.url = data.list.url

			this.$store.commit('listReset')
		},
	},
}
</script>

<style lang="scss" scoped>
.header-bid-change {
	font-family: 'Inter-Medium';
	font-size: 24px;
}
</style>
